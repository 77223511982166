import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Home from '../src/page/home'
import About from '../src/page/about'
import Expert from '../src/page/expertpanel'
import Parents from '../src/page/parents'
import Healthcare from '../src/page/healthcare'
import Clinic from '../src/page/clinic'
import Contact from '../src/page/contact'
import Login from '../src/page/login'
import Module from '../src/page/module'
import Register from '../src/page/register'
import AskExpert from '../src/page/askExpert'
import Materials from '../src/page/materials'
import Profile from '../src/page/profile'
import UpdateProfile from '../src/page/updateProfile'
import ChangePassword from '../src/page/changePassword'
import DownloadMaterials from '../src/page/downloadMaterials'
import ModuleContent from '../src/page/moduleContent'
import ClinicInformation from './page/clinicInformation'
import MaterialsInformation from './page/materialsInformation'
import Verify from './page/verify'
import ForgotPassword from './page/forgotPassword'
import Article1 from './page/parents/article1'
import Article2 from './page/parents/article2'
import Article3 from './page/parents/article3'
import ReadMe from './page/readMe'
import Tnc from './page/tnc'
import Privacy from './page/privacy'
import Disclaimer from './page/disclaimer'
import ParentsBM from '../src/page/parentsBahasa'
import Article1BM from './page/bahasa/article1'
import Article2BM from './page/bahasa/article2'
import Article3BM from './page/bahasa/article3'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'
import { logoutUser, saveUser } from './redux/slices/userSlice'
import Legacy from './page/legacy'
import FinalCertificate from './page/FinalCertificate'

function App() {
  const isAuthenticated = useSelector(state => state.user)
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()

  useEffect(() => {
    if (token)
      axios
        .post('/api/auth/checkToken', { token })
        .then(res => {
          localStorage.setItem('imfed-user', res.data.data?.username)
          dispatch(saveUser(res.data))
        })
        .catch(err => {
          localStorage.removeItem('imfed-user')
          localStorage.removeItem('imfed-token')
          dispatch(logoutUser())
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/expert" element={<Expert />} />
          <Route path="/about" element={<About />} />
          <Route path="/parents" element={<Parents />} />
          <Route
            path="/healthcare"
            element={
              isAuthenticated ? <Healthcare /> : <Navigate to="/login" />
            }
          />
          <Route path="/clinic" element={<Clinic />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/login"
            element={<Login isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/module"
            element={isAuthenticated ? <Module /> : <Navigate to="/login" />}
          />
          <Route path="/register" element={<Register />} />
          <Route
            path="/askExpert"
            element={isAuthenticated ? <AskExpert /> : <Navigate to="/login" />}
          />
          <Route
            path="/moduleContent"
            element={
              isAuthenticated ? <ModuleContent /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/materials"
            element={isAuthenticated ? <Materials /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
          />
          <Route
            path="/updateProfile"
            element={
              isAuthenticated ? <UpdateProfile /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/changePassword"
            element={
              isAuthenticated ? <ChangePassword /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/downloadMaterials"
            element={
              isAuthenticated ? <DownloadMaterials /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/materialsInformation"
            element={
              isAuthenticated ? (
                <MaterialsInformation />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/clinic-listing" element={<ClinicInformation />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/article1" element={<Article1 />} />
          <Route path="/article2" element={<Article2 />} />
          <Route path="/article3" element={<Article3 />} />
          <Route path="/readMe" element={<ReadMe />} />
          <Route path="/tnc" element={<Tnc />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/bm/article1" element={<Article1BM />} />
          <Route path="/bm/article2" element={<Article2BM />} />
          <Route path="/bm/article3" element={<Article3BM />} />
          <Route path="/bm/parents" element={<ParentsBM />} />
          <Route path="/legacy" element={<Legacy />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
