import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { message } from 'antd'

const ModuleAssessment = ({ navigate }) => {
  const location = useLocation()

  const moduleId = location.state
  const userId = useSelector(state => state.user.user?.data.id)

  const [questions, setQuestions] = useState([])
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [showResult, setShowResult] = useState(false)
  const [isCorrect, setIsCorrect] = useState(null)

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.post('/api/module/fetchQuestion', {
          moduleId
        })
        setQuestions(response.data.data)
        // console.log(response)
      } catch (error) {
        console.error('Error fetching questions:', error)
        setQuestions([])
      }
    }

    if (moduleId) {
      fetchQuestions()
    }
  }, [moduleId])

  const handleAnswerChange = event => {
    const answerId = event.target.value
    const isSelected = selectedAnswers.includes(answerId)

    if (isSelected) {
      setSelectedAnswers(prevSelectedAnswers =>
        prevSelectedAnswers.filter(id => id !== answerId)
      )
    } else {
      setSelectedAnswers(prevSelectedAnswers => [
        ...prevSelectedAnswers,
        answerId
      ])
    }

    setShowResult(false)
  }

  // console.log('userId ----', userId)

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1)
      setSelectedAnswers([])
      setShowResult(false)
    } else {
      if (!userId) {
        message.error('Please login to continue')
        setTimeout(() => {
          navigate('/login')
        }, 2000)
      }
      const updateAssessment = async () => {
        try {
          const response = await axios.post(
            '/api/module/updateAssessmentStatus',
            {
              moduleId,
              userId
            }
          )
          if (response.status === 200) {
            window.location.reload() // Refresh the page
          } else {
            console.log('Failed to update assessment status')
          }
        } catch (error) {
          console.error('Error updating assessment status:', error)
        }
      }

      updateAssessment()
    }
  }

  const handleSubmit = () => {
    const currentQuestion = questions[currentQuestionIndex]
    const correctAnswerIds = currentQuestion.Answers.filter(
      answer => answer.correctAnswer === '1'
    ).map(answer => answer.id)

    const isAnswerCorrect =
      correctAnswerIds.length === selectedAnswers.length &&
      correctAnswerIds.every(id => selectedAnswers.includes(id))

    setIsCorrect(isAnswerCorrect)
    setShowResult(true)

    if (isAnswerCorrect) {
      setIsCorrect(null)
      setShowResult(false)
      handleNext()
    }
  }

  // console.log('questions', questions)

  return (
    <div className="video">
      <div className="assessment-container">
        <div style={{ padding: '50px' }}>
          {Array.isArray(questions) &&
            questions.length > 0 &&
            currentQuestionIndex < questions.length && (
              <div key={questions[currentQuestionIndex].id}>
                <h4>{`${questions[currentQuestionIndex].question}`}</h4>
                {Array.isArray(questions[currentQuestionIndex].Answers) &&
                  questions[currentQuestionIndex].Answers.map(answer => (
                    <div key={answer.id}>
                      <label>
                        <input
                          type="checkbox"
                          value={answer.id}
                          checked={selectedAnswers.includes(answer.id)}
                          onChange={handleAnswerChange}
                          style={{ marginRight: '0.4rem' }}
                        />
                        {answer.answer}
                      </label>
                      <br />
                    </div>
                  ))}
                {showResult && (
                  <p style={{ color: isCorrect ? 'green' : 'red' }}>
                    {isCorrect
                      ? 'Correct answer! Moving to the next question.'
                      : 'Wrong answer! Try again.'}
                  </p>
                )}
                <button
                  style={{
                    margin: '0 auto',
                    display: 'block',
                    marginTop: '20px'
                  }}
                  htmlType="button"
                  onClick={isCorrect ? handleNext : handleSubmit}
                >
                  {currentQuestionIndex < questions.length - 1
                    ? 'Next'
                    : 'Submit'}
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default ModuleAssessment
